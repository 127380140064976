import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Spin,
  message,
  Select,
  Card,
  Space,
  Tag,
  Input,
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useQuery, useMutation } from "react-query";

import handleDataMutation from "../../helper/dataMutation";
import {
  getProductsByVendorID,
  productToBanner,
  getAppBannerEdit,
} from "../../api";
import { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

export const AddProducts = () => {
  const {
    id,
    vendorID,
    setIsAddVendor,
    setVendorID,
    isAddVendor,
    appBanner_product,
    setAppBannerProduct,
    type,
    setType,
    setPolygon,
    setPriorty,
    setId,
    setName,
    setImg,
    setEndDate,
    setStartDate,
  } = useAppBanner();
  const [list, setList] = useState([]);
  const [searchlist, setSearchList] = useState([]);
  const [filter, setFilter] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [Status, setStatus] = useState(true);

  
  const handleVendorSearch = (val) => {

    setProductSearch(val);
    if (val !== "") {
      let productSearch = list?.filter(
        (el) => el?.product?.product?.includes(val) || el?.product?.barcode?.includes(val)
      );
      setSearchList(
        productSearch.length !== 0 ? productSearch : [{ searchRes: false }]
      );
    } else {
      setSearchList([]);
    }
  };

  const { isLoading: isEditData, data: editData } = useQuery(
    [id],
    getAppBannerEdit,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );



  useEffect(() => {
 
    if (id) {
      setList(
        editData?.data?.appBanner_product
          ? editData?.data?.appBanner_product
          : []
      );
    }
  }, [id,editData?.data]);

  const SortableList = SortableContainer(({ items }) => {

    return (
      <div>
        {items?.map((value, index) =>
          value.searchRes !== false ? (
            <Card
              bordered={false}
              bodyStyle={{ padding: "10px 16px", backgroundColor: "#f6f6f6" }}
              style={{ marginBottom: 10 }}
            >
              <div
                className="app-space-between"
                style={{ alignItems: "center" }}
              >
                <Space size={16}>
                  <Space direction="vertical" size={1}>
                    <Text>{`${value?.product?.product || value?.product} ${value?.product?.name || ""} (${
                      value?.product?.size || value?.size
                    } ${value?.unit || ""})`}</Text>
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      {value?.product?.description}
                    </Text>
                  </Space>
                </Space>
                <Button
                  onClick={(e) => {
            
                    e.stopPropagation();
                    setList(list.filter((el) => el.id != value.id));
                    if (productSearch) {
                      setSearchList(
                        searchlist.filter((el) => el.id != value.id).length !==
                          0
                          ? searchlist.filter((el) => el.id != value.id)
                          : [{ searchRes: false }]
                      );
                    }
                  }}
                  icon={<DeleteOutlined />}
                  size="small"
                  type="text"
                  danger
                />
              </div>
            </Card>
          ) : null
        )}
      </div>
    );
  });

  const { isLoading: productLoading, data: products } = useQuery(
    ["vendorProductVariation", vendorID, 1, 5000, Status, filter],
    getProductsByVendorID,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!vendorID,
    }
  );

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    productToBanner,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "appBanner",
          setterList: [() => setIsAddVendor(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  let onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMoveImmutable(list, oldIndex, newIndex));
  };



  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  const handleSave = () => {
    let dataForm = {
      appBanner_product: list?.map((el, index) => {
        return { productID: el.id, priority: index + 1 };
      }),
      id,
    };

    editMutate(dataForm);
  };

  const resetState = () => {
    setId(null);
    setVendorID(null);
    setImg(null);
    setType(null);
    setStartDate(null);
    setEndDate(null);
    setName(null);
    setPolygon(null);
    setPriorty(null);
    setProductSearch("");
    setList([]);
    setSearchList([]);
    setAppBannerProduct([]);
  };

  return (
    <Modal
      title={<Title level={5}>Product to Banner</Title>}
      className="app-modal"
      centered
      visible={isAddVendor}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsAddVendor(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Search
            onSearch={(val) => handleVendorSearch(val)}
            placeholder="Search on product in this banner..."
          />
        </Col>
        <Col span={24}>
          <Select
            showSearch
            filterOption={false}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            onChange={(val) => {
              if (val) {
                if (type == "BANNER") {
                  setList([
                    products?.data?.records?.find((el) => el.id == val),
                  ]);
                } else {
                  setList([
                    ...list,
                    products?.data?.records?.find((el) => el.id == val),
                  ]);
                }
              }
            }}
            style={{ width: "100%" }}
            placeholder="Find vendor Product variation to add on this banner"
            allowClear
            onClear={(e) => setFilter("")}
          >
            {!productLoading ? (
              products?.success ? (
                products?.data?.records?.map((el) => (
                  <Option
                    key={el.id}
                    id={el.id}
                    value={el.id}
                    disabled={isRepeated(el.id)}
                  >
                    {`${el?.product} ${el?.name || ""} (${el?.size} ${
                      el?.unit || ""
                    })`}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>

        <Col span={24}>
          <div
            style={{
              minHeight: 400,
              maxHeight: "70vh",
              overflow: "auto",
            }}
          >
            <SortableList
              distance={1}
              items={searchlist.length > 0 ? searchlist : list}
              onSortEnd={onSortEnd}
            />
          </div>
        </Col>
        <Col span={18}>
          <Button
            loading={editLoading}
            type="primary"
            onClick={handleSave}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Col>
        <Col span={6} style={{ marginTop: "6px" }}>
          <Tag color="pink"> Total Products {list.length}</Tag>
        </Col>
      </Row>
    </Modal>
  );
};
