import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useOrderView } from "./store";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { changeOrderStatus, getDeclineReason } from "../../api";
import handleDataMutation from "../../helper/dataMutation";
import { useState } from "react";

const { Title } = Typography;
const { Option } = Select;

export const CreateCancelReason = () => {
  const { id } = useParams();
  const [reasonSearch, setReasonSearch] = useState("");

  const {
    isCreateCancelModal,
    setIsCreateCancelModal,
    setIsSelectReason,
    selectedReason,
    setSelectedReason,
  } = useOrderView();

  const { data: reasonData } = useQuery(
    ["declineReasons", reasonSearch, 1, 500 , "BACKOFFICE_USER"],
    getDeclineReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateCancelModal,
    }
  );

  const { mutate: changeStatusMutate, isLoading: changeStatusLoading } =
    useMutation(changeOrderStatus, {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "orderInfo",
          setterList: [
            () => setIsSelectReason(false),
            () => setSelectedReason(null),
          ],
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const onFinish = (val) => {
    let dataForm = {
      id: Number(id),
      status: selectedReason !== null ? selectedReason : "BACKOFFICE_REJECTED",
      declineReasonID: val.declineReasonID,
    };
    setIsSelectReason(true);
    changeStatusMutate(dataForm);
    setIsCreateCancelModal(false);
  };

  return (
    <Modal
      title={<Title level={5}> Select Cancel Reason </Title>}
      className="app-modal"
      centered
      visible={isCreateCancelModal}
      onCancel={() => {
        setIsCreateCancelModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Cancel Reasons"
              name="declineReasonID"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "100%" }}
                filterOption={false}
                dropdownMatchSelectWidth={false}
                allowClear
                showSearch
                onSearch={(val) => setReasonSearch(val)}
                placeholder="Select Reason"
              >
                {reasonData?.data?.records.map(
                  (val) =>
                    val.userType === "BACKOFFICE_USER" && (
                      <Option key={val.id} value={val.id}>
                        {val.arTitle}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={changeStatusLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
